import React, { useEffect, useState } from "react";
import CloseIcon from "@mui/icons-material/Close";
import Dropzone from "react-dropzone";
import axiosClientInterceptors from "../ReusableComponents/axiosClientInterceptors";
import useAnalytics from "../CustomHook/useAnalytics";

const TicketPopup = ({ handleClose, getAllTickets, NameEmail }) => {
  const { trackEvent } = useAnalytics();
  // const [count, setcount] = useState(false);
  const [isValid, setIsValid] = useState({
    Issue: true,
    textareadata: true,
  });
  const [fileName, setfileName] = useState([]);
  const [textareadata, settextareadata] = useState("");
  const [Issue, setIssue] = useState("");
  const [fileshow, setfileshow] = useState("");
  const locations = window.location;
  const handleImage = (acceptedFiles) => {
    setfileName(acceptedFiles);
    setfileshow(acceptedFiles[0].path);
  };

  function validateData() {
    // setIssue("");
    let subErr = false;
    let descErr = false;
    // console.log("validating data..");
    if (Issue.length < 1 || textareadata.length < 1) {
      Issue.length < 1 && (subErr = true);
      textareadata.length < 1 && (descErr = true);
      setIsValid({ Issue: !subErr, textareadata: !descErr });
    } else {
      setIsValid({ Issue: true, textareadata: true });
      addTicket();
    }
  }

  const addTicket = async () => {
    handleClose();

    trackEvent("added ticket", {
      button_label: "Support added ticket",
      from: "web",
    });

    setIssue("");
    const addProjData = new FormData();

    fileName?.forEach((image) => {
      addProjData.append("ticket_doc", image);
    });
    addProjData.append("ticket_notes", textareadata);
    addProjData.append("ticket_subject", Issue);

    const res = await axiosClientInterceptors.post(
      `/add-ticket?partner_id=${localStorage.getItem("supportId")}`,
      addProjData,
      {
        headers: {
          "Content-Type": "multipart/form-data",
        },
      }
    );
    getAllTickets();
  };

  return (
    <div className="popup p-4">
      <div className="d-flex justify-content-between align-items-center">
        <p className="mb-0 titlepopup">Submit a request</p>
        <CloseIcon
          style={{ cursor: "pointer" }}
          onClick={() => handleClose()}
        />
      </div>
      <div className="row mt-4">
        <div className="col-md-6">
          <p className="FullName">Email</p>
          <input
            type="text"
            className="w-100 inputtag"
            defaultValue={NameEmail.email}
          />
        </div>
        <div className="col-md-6">
          <p className="FullName">Mobile Number</p>
          <input
            type="text"
            className="w-100 inputtag"
            defaultValue={NameEmail.mobile}
          />
        </div>
        <div className="col-md-12 mt-3">
          <p className="FullName">Issue Types</p>
          <select
            name="cars"
            id="cars"
            className="inputtagedit w-100 "
            value={Issue}
            onChange={(e) => {
              console.log("textareadata", e.target.value);
              setIssue(e.target.value);
            }}
          >
            <option value="" disabled selected>
              Choose Issue
            </option>
            <option value="Profile">Profile</option>
            <option value="Payment">Payment</option>
            <option value="Bank Account">Bank Account</option>
            <option value="Lead">Lead</option>
            <option value="Account Related">Account Related</option>
            <option value="Other">Other</option>
          </select>
          {!isValid.Issue && <p className="error">required</p>}
        </div>
        <div className="col-md-12 mt-3">
          <p className="FullName">Description</p>
          <textarea
            value={textareadata}
            placeholder="eg. facing problems in adding leads"
            onChange={(e) => {
              console.log("textareadata", textareadata);
              settextareadata(e.target.value);
            }}
            className="w-100 inputtagedit"
          ></textarea>
          {!isValid.textareadata && <p className="error">required</p>}
        </div>
        <div className="col-md-12 mt-3">
          <p className="FullName">Attachments</p>
          <Dropzone onDrop={handleImage}>
            {({ getRootProps, getInputProps }) => (
              <div
                {...getRootProps({
                  className: "uplode_file_dot_border rounded",
                })}
              >
                {/* <div className="mb-3">
                  <img className="mx-auto" src={File_logo} alt="..." />
                </div> */}
                <input name="bulkdata" multiple {...getInputProps()} />

                {fileshow ? (
                  <p className="uplode_file_text mb-0">{fileshow}</p>
                ) : (
                  <p className="uplode_file_text mb-0">
                    Add file{" "}
                    <span style={{ color: "#969696" }}>or drop files here</span>{" "}
                  </p>
                )}
              </div>
            )}
          </Dropzone>
        </div>

        <div className="col-md-12 mt-3" onClick={() => validateData()}>
          <button className="w-100 submitbutton " style={{ cursor: "pointer" }}>
            Submit
          </button>
        </div>
      </div>
    </div>
  );
};

export default TicketPopup;
